import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  lng: 'de',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    // ru: {
    //   translations: require('./locales/ru/translations.json')
    // },
    // tr: {
    //   translations: require('./locales/tr/translation.json')
    // },
    it: {
      translations: require('./locales/it/translation.json')
    },
    de: {
      translations: require('./locales/de/translation.json')
    },
    // es: {
    //   translations: require('./locales/es/translation.json')
    // },
    cz: {
      translations: require('./locales/cz/translation.json')
    },
    fr: {
      translations: require('./locales/fr/translation.json')
    },
    pl: {
      translations: require('./locales/pl/translations.json')
    }
    
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'de','cz','fr','it','pl'];

export default i18n;