import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  isButtonPressed: false,
  orderButtonIsPressed: false,
  toolsDrawer: false,
  AssetsList: [],
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setIsButtonPressedAction: (state, action: PayloadAction<boolean>) => {
      state.isButtonPressed = action.payload;
    },
    setOrderButtonAction: (state, action: PayloadAction<boolean>) => {
      state.orderButtonIsPressed = action.payload;
    },
    setToolsDrawer: (state, action: PayloadAction<boolean>) => {
      state.toolsDrawer = action.payload;
    },
    setAssetsList: (state, action) => {
      state.AssetsList = action.payload;
    },
  },
});

export const { setIsButtonPressedAction, setOrderButtonAction, setToolsDrawer, setAssetsList } = rootSlice.actions;

export default rootSlice.reducer;
