import React, { useState } from "react";
import styles from "./Withdrawal.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask"; // Импортируем InputMask
import secureLocalStorage from "react-secure-storage";
import Select, { components } from "react-select";
import axios from "axios";
import { colourStylesCurrency } from "../../components/SelectCurrencyStyles";

export default function Withdrawal({
  theme,
  account,
  accounts,
  setSnackType,
  setSnackMessage,
  handleClick,
  handleSetDrawerWithdrawal,
}) {
  const { t } = useTranslation();
  const [withdrawalType, setWithdrawalType] = useState(1);
  const [amount, setAmount] = useState("");
  const [fullname, setFullName] = useState("");
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  // Добавляем состояние для поля ввода кредитной карты
  const [creditCard, setCreditCard] = useState("");
  const { id } = useParams();
  const Withdrawal = () => {
    if (!withdrawalType) {
      handleClick();
      setSnackType("error");
      setSnackMessage(t("WithdrawalTypeError"));
      return;
    }
    if (!creditCard) {
      handleClick();
      setSnackType("error");
      setSnackMessage(t("WithdrawalNumberError"));
      return;
    }
    if (!fullname) {
      handleClick();
      setSnackType("error");
      setSnackMessage(t("WithdrawalNameError"));
      return;
    }
    if (!amount || amount < 0 ) {
      handleClick();
      setSnackType("error");
      setSnackMessage(t("WithdrawalAmountInvalid"));
      return;
    }
    if (amount > account?.AccountBalance) {
      handleClick();
      setSnackType("error");
      setSnackMessage(t("WithdrawalAmountError"));
      return;
    }
    try {
      axios.post(process.env.REACT_APP_test_url + `/api/contact/email`, {
        headers: {},
        email: secureLocalStorage.getItem("user").Email,
        message: "Contact about withdrawal of " + amount + " USD",
        name: `${secureLocalStorage.getItem("user").FirstName} ${
          secureLocalStorage.getItem("user").SecondName
        } ${secureLocalStorage.getItem("user").LastName}`,
        type:
          withdrawalType == 1
            ? t("Bank Transfer")
            : withdrawalType == 2
            ? t("Crypto currency")
            : t("Card"),
        account: creditCard,
        firstname: secureLocalStorage.getItem("user").FirstName,
        lastname: secureLocalStorage.getItem("user").SecondName,
        platformid: id ? id : account?.Id,
        price: amount,
      });
      navigate("/cabinet");
      setSnackType("success");
      setSnackMessage(t("ManagerWill"));
      handleClick();
      handleSetDrawerWithdrawal();
    } catch (e) {
      console.log(e);
    }
  };

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          props.isSelected
            ? styles.custom_option_selected
            : styles.custom_option
        }
      >
        <div
          className={styles.option}
          style={{
            color: props.data.color,
            backgroundColor: props.data.background,
            width: "auto",
          }}
        >
          {props.data.label}
        </div>
      </div>
    );
  };

  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg
            width={"1.7vh"}
            height={"1.6vh"}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5003 6L8.83366 10L4.16699 6"
              stroke="#A0A0AB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  const types = [
    { value: 1, label: t("Bank Transfer") },
    { value: 2, label: t("Crypto currency") },
    { value: 3, label: t("Card") },
  ]
  return (
    <div className={styles.withdrawal}>
      <div className={styles.withdrawal_title}>
        <div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.6667 9.33325V13.3333M12.6667 13.3333L14 11.9999M12.6667 13.3333L11.3334 11.9999"
                stroke="var(--font-mine)"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.6667 8.00008C14.6667 5.48592 14.6667 4.22885 13.8857 3.4478C13.1046 2.66675 11.8475 2.66675 9.33337 2.66675H6.66671C4.15255 2.66675 2.89547 2.66675 2.11442 3.4478C1.33337 4.22885 1.33337 5.48592 1.33337 8.00008C1.33337 10.5142 1.33337 11.7713 2.11442 12.5524C2.89547 13.3334 4.15255 13.3334 6.66671 13.3334H9.33337"
                stroke="var(--font-mine)"
                stroke-linecap="round"
              />
              <path
                d="M6.66667 10.6667H4"
                stroke="var(--font-mine)"
                stroke-linecap="round"
              />
              <path
                d="M8.66671 10.6667H8.33337"
                stroke="var(--font-mine)"
                stroke-linecap="round"
              />
              <path
                d="M1.33337 6.66675L14.6667 6.66675"
                stroke="var(--font-mine)"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span>{t("Withdrawal")}</span>
        </div>
        <button
          onClick={handleSetDrawerWithdrawal}
          className={styles.closeButton}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12 4.00003L4 12M3.99997 4L11.9999 12"
              stroke="var(--font-mine)"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.withdrawal_body}>
        <div className={styles.withdrawal_form}>
          <div className={styles.select} style={{width:'100%'}}>
            <Select
              classNamePrefix="custom_select"
              className={`${styles.select_select} custom-select-container`}
              placeholder={t("Currency")}
              menuPlacement="auto"
              options={types}
              styles={colourStylesCurrency(theme)}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: CustomOption,
              }}
              value={types.find(el=>el.value === withdrawalType)}
              onChange={(selectedOption) =>
                setWithdrawalType(selectedOption.value)
              }
            />
          </div>
          {/* <div className={styles.withdrawal_type}>
            <div
              className={`${styles.type} ${
                withdrawalType === 1 ? styles.active : ""
              }`}
              onClick={() => setWithdrawalType(1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"2.4vh"}
                height={"2.4vh"}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 19C6.19108 19 4.78661 19 3.77772 18.3259C3.34096 18.034 2.96596 17.659 2.67412 17.2223C2 16.2134 2 14.8089 2 12C2 9.19108 2 7.78661 2.67412 6.77772C2.96596 6.34096 3.34096 5.96596 3.77772 5.67412C4.78661 5 6.19108 5 9 5L15 5C17.8089 5 19.2134 5 20.2223 5.67412C20.659 5.96596 21.034 6.34096 21.3259 6.77772C22 7.78661 22 9.19108 22 12C22 14.8089 22 16.2134 21.3259 17.2223C21.034 17.659 20.659 18.034 20.2223 18.3259C19.2134 19 17.8089 19 15 19H9Z"
                  stroke="#FCFCFC"
                />
                <path
                  d="M9 9C7.34315 9 6 10.3431 6 12C6 13.6569 7.34315 15 9 15"
                  stroke="#FCFCFC"
                />
                <path
                  d="M15 9C16.6569 9 18 10.3431 18 12C18 13.6569 16.6569 15 15 15"
                  stroke="#FCFCFC"
                />
                <path d="M9 5V18.5" stroke="#FCFCFC" strokeLinecap="round" />
                <path d="M15 5V18.5" stroke="#FCFCFC" strokeLinecap="round" />
              </svg>
              {t("Bank Transfer")}
            </div>
            <div
              className={`${styles.type} ${
                withdrawalType === 2 ? styles.active : ""
              }`}
              onClick={() => setWithdrawalType(2)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"2.4vh"}
                height={"2.4vh"}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 8V6M12 18V16M9 12H13C14.1046 12 15 11.1046 15 10C15 8.89543 14.1046 8 13 8H9V12ZM9 12H14C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16H9V12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="#FCFCFC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {t("Crypto currency")}
            </div>
            <div
              className={`${styles.type} ${
                withdrawalType === 3 ? styles.active : ""
              }`}
              onClick={() => setWithdrawalType(3)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={"2.4vh"}
                height={"2.4vh"}
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 14V20M19 20L21 18M19 20L17 18"
                  stroke="#FCFCFC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14"
                  stroke="#FCFCFC"
                  strokeLinecap="round"
                />
                <path d="M10 16H6" stroke="#FCFCFC" strokeLinecap="round" />
                <path d="M13 16H12.5" stroke="#FCFCFC" strokeLinecap="round" />
                <path d="M2 10L22 10" stroke="#FCFCFC" strokeLinecap="round" />
              </svg>
              {t("Card")}
            </div>
          </div> */}
          <div className={styles.amount}>
            <label>{t("Amount of payment")}</label>
            <input
            onChange={e => {
                const value = e.target.value;
                // Разрешаем пустое значение или число с максимум 2 знаками после точки/запятой
                if (value === '' || /^\d*([.,]\d{0,2})?$/.test(value)) {
                // Заменим запятую на точку, если нужно (для стандартизации)
                setAmount(value.replace(',', '.'));
                }
            }}
            value={amount}
            placeholder={t('Amount of payment')}
            type="text" // используем text, чтобы не ограничивать ввод, как у type="number"
            inputMode="decimal" // отображает цифровую клавиатуру на мобильных
            />
          </div>
          {withdrawalType === 3 ? (
            <div className={styles.amount}>
              <label>{t("Credit card")}</label>
              <InputMask
                mask="9999 9999 9999 9999" // Маска для кредитной карты
                maskChar="*"
                value={creditCard}
                onChange={(e) => setCreditCard(e.target.value)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    placeholder={t("Credit card")}
                    type="text"
                    // Добавьте необходимые классы или стили
                  />
                )}
              </InputMask>
            </div>
          ) : withdrawalType === 1 ? (
            <div className={styles.amount}>
              <label>{t("IBAN")}</label>
              <input
                placeholder={t("IBAN")}
                type="text"
                value={creditCard}
                onChange={(e) => setCreditCard(e.target.value)}
              />
            </div>
          ) : (
            <div className={styles.amount}>
              <label>{t("Crypto Wallet")}</label>
              <input
                placeholder={t("Crypto Wallet")}
                type="text"
                value={creditCard}
                onChange={(e) => setCreditCard(e.target.value)}
              />
            </div>
          )}
          <div className={styles.amount}>
            <label>{t("Full name")}</label>
            <input
              onChange={(e) => setFullName(e.target.value)}
              value={fullname}
              placeholder={t("Full name")}
              type="text"
            />
          </div>
          {withdrawalType !== 2 &&
          <div className={styles.amount}>
            <label>{t("Recipient address")}</label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              placeholder={t("Recipient address")}
              type="text"
            />
          </div>}
        </div>
      </div>
      <div className={styles.buttons}>
        <button onClick={handleSetDrawerWithdrawal}>{t("Cancel")}</button>
        <button
          onClick={() => {
            Withdrawal();
            handleSetDrawerWithdrawal();
          }}
        >
          {t("Continue")}
        </button>
      </div>
    </div>
  );
}
